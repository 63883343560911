var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v("Acompanhamento dos Atendimentos")]),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Data início","type":"date","clearable":""},model:{value:(_vm.data_inicio_),callback:function ($$v) {_vm.data_inicio_=$$v},expression:"data_inicio_"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Data fim","type":"date","clearable":""},model:{value:(_vm.data_fim_),callback:function ($$v) {_vm.data_fim_=$$v},expression:"data_fim_"}})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.sessoes,"search":_vm.search,"headers":_vm.headers},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{staticClass:"mx-0",attrs:{"label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.get_sessoes()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)]},proxy:true},{key:"item.equipe",fn:function(ref){
var item = ref.item;
return _vm._l((item.equipe),function(i){return _c('v-chip',{key:i.id,staticClass:"ma-1"},[_vm._v(" "+_vm._s(i.first_name)+" ")])})}},{key:"item.see",fn:function(ref){
var item = ref.item;
return [_c('AddAtendimento',{attrs:{"current-item":item}})]}},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatabr")(item.data_inicio))+" ")]}},{key:"item.data_fim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDatabr")(item.data_fim))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }