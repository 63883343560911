<template>
    <v-container fluid>
        <h2>Acompanhamento dos Atendimentos</h2>
        <v-row>
            <v-col cols="3">
        <v-text-field
          v-model="data_inicio_"
          outlined
          label="Data início"
          type="date"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="3">
      
        <v-text-field
          v-model="data_fim_"
          outlined
          label="Data fim"
          type="date"
          clearable
        ></v-text-field>
      </v-col>
        </v-row>
 
        <v-data-table
            :loading="loading"
            :items="sessoes"
            :search="search" 
            :headers="headers"
        >
            <template v-slot:top>
                <v-row>
                    <v-col cols="11">
                        <v-text-field 
                            v-model="search" 
                            label="Pesquisar" 
                            class="mx-0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="text-right">
                        <!-- <Tab 
                            :current-item="headers" 
                            @refresh_atendimentos="get_sessoes()" 
                        /> -->
                        <v-btn icon @click="get_sessoes()"><v-icon>mdi-refresh</v-icon></v-btn>
                        <!-- <Editar :current-item="{}" /> -->
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.equipe`]="{ item }">
            <v-chip class="ma-1" v-for="i in item.atendimento" :key="i.id">
              {{ i.first_name }} {{ i.last_name }}
            </v-chip>
          </template>
          <template 
            v-slot:[`item.see`]="{ item }">
            <AddAtendimento :current-item="item" />
          </template>
            <template v-slot:[`item.data_inicio`]="{ item }">
                {{ item.data_inicio | formatDatabr }}
            </template>
            <template v-slot:[`item.data_fim`]="{ item }">
                {{ item.data_fim | formatDatabr }}
            </template>
            <template v-slot:[`item.equipe`]="{ item }">
                <v-chip class="ma-1" v-for="i in item.equipe" :key="i.id">
              {{ i.first_name }}
            </v-chip>
            </template>
        </v-data-table>
   
    </v-container>
</template>
<script>
import api from "@/http"
export default {
    name:"Atendimentos",
    components:{
        AddAtendimento: ()=>import('./AddAtendimento.vue')
    },
    data:()=>({ 
        loading:false,
        items:[],
        search: '',
        data_inicio_:'',
        data_fim_:'',
        headers:[
            // falta trazer as seções feitas
            { text: "Id", align: "start", sortable: true, value: "id" },
            { text: "Convênio", align: "start", sortable: true, value: "paciente.operadora.nome" },
            { text: "Paciente", align: "end", sortable: true, value: "paciente.nome"}, 
            { text: "Senha Autotização", align: "start", sortable: true, value: "senha"},
            { text: "Equipe", align: "start", sortable: true, value: "equipe" },
            { text: "Procedimento", align: "end", sortable: true, value: "procedimento.nome"},
            { text: "Qtd. Liberada", align: "center", sortable: true, value: "qtd" },
            { text: "Qtd. Concluída", align: "center", sortable: true, value: "evolucao" },
            { text: "Frequência", align: "end", sortable: true, value: "frequencia.nome"},
            { text: "Data ínicio", align: "center", sortable: true, value: "data_inicio"},
            { text: "Data Fim", align: "center", sortable: true, value: "data_fim"},
            { text: "Detalhar", align: "end", sortable: true, value: "see" },
        ]
    }),
    methods:{
        get_sessoes() {
            this.loading = true;
            api("atendimentos/sessoes/")
                .then((response) => {
                this.items = response.data;
                console.log(this.items.map(e=>e.equipe))
            })
            .catch(() => {
                alert("Erro no link ao solicitar sessões");
            })
            .finally(() => {
                this.loading = false;
            });
        },
        

    },
    mounted() {
        this.get_sessoes()
    },
    computed: {
        sessoes(){
      
      let c=this.items.filter((e)=>{
        return this.data_inicio_>= e.data_inicio&&this.data_fim_<=e.data_fim
        
      })
      
      if(c.length==0){
        // b = this.items
        c=this.items
        
      }
      return c 
    }
    }
}
</script>